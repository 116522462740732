<template>
<div>
  <div class="swiper-button-left">
        <img src="../assets/left.png" alt="" />
      </div>
  <div id="root">
    <div class="swiper-container">
      <div class="zoomleft"></div>
      <div class="swiper-wrapper" style="">
        <div class="swiper-slide"><img src="../assets/state.png" /></div>
        <div class="swiper-slide"><img src="../assets/index.png" /></div>
        <div class="swiper-slide"><img src="../assets/password.png" /></div>
        <div class="swiper-slide"><img src="../assets/box.png" /></div>
        <div class="swiper-slide"><img src="../assets/imagep.png" /></div>
        <div class="swiper-slide"><img src="../assets/chatimage.png" /></div>
        <!-- <div class="swiper-slide"><img src="../assets/logo.png" /></div>
        <div class="swiper-slide"><img src="../assets/logo.png" /></div> -->
      </div>
      <div class="zoomright"></div>
      
      
    </div>
  </div>
  <div class="swiper-button-right">
        <img src="../assets/right.png" alt="" />
      </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  mounted() {
    this.$nextTick(() => {
      new Swiper(".swiper-container", {
        loop: true, // 循环模式选项
        loopedSlides: 6,
        prevButton: ".swiper-button-left",
        nextButton: ".swiper-button-right",
        // 如果需要分页器
        // pagination: {
        //   el: '.swiper-pagination',
        // },
      });
    });
  },
};
</script>

<style lang="scss" scoped>
#root {
  overflow: hidden !important;
  width: 1100px;
  // margin: 0 auto;
      margin: 10px auto;
    height: 610px;
}
.swiper-container {
  overflow: visible !important;
  width: 335px;
  height: 587px;
}
.swiper-wrapper{
// transform: translate3d(-2360px, 0px, 0px) !important;
    transition-duration: 0ms;
}
.swiper-container .swiper-wrapper .swiper-slide {
  width: 600px;
  border-radius: 20px;
  // background: palegreen;
  // margin-right: 15px;
  //   margin-left: 15px;

}
.swiper-container .swiper-wrapper .swiper-slide img {
  border-radius: 20px;
  width: 100%;
  height: 94%;
      margin-top: 15px;
}
/* .swiper-container .swiper-wrapper .swiper-slide-prev{ margin-top: 18px; height: 284px!important;}
  .swiper-container .swiper-wrapper .swiper-slide-prev img{ height: 284px!important;}
  .swiper-container .swiper-wrapper .swiper-slide-next{ margin-top: 18px; height: 284px!important;}
  .swiper-container .swiper-wrapper .swiper-slide-next img{ height: 284px!important;} */
.swiper-container .swiper-wrapper .swiper-slide-active {
  width: 600px ;
  margin-right: 30px;
  margin-left: 30px;
}
.swiper-button-right {
  width: 50px;
  height: 50px;
  z-index: 9999999999;
  position: absolute;
  left: 1140px;
  top: 50%;
  margin-top: -25px;
  img {
    width: 100%;
    height: 100%;
  }
}
.swiper-button-left {
  width: 50px;
  height: 50px;
  z-index: 9999999999;
  position: absolute;
  left: 38px;
  top: 50%;
  margin-top: -25px;
  img {
    width: 100%;
    height: 100%;
  }
}
.zoomleft {
    width: 140px;
    height: 614px;
    background: linear-gradient(270deg, #ffffff 0%, #ffffff 100%);
    filter: blur(10px);
    position: absolute;
    left: -454px;
    top: -27px;
    z-index: 999;
}
.zoomright {
     width: 140px;
    height: 614px;
    background: linear-gradient(270deg, #ffffff 0%, #ffffff 100%);
    filter: blur(10px);
    position: absolute;
    left: 670px;
    top: -27px;
    z-index: 999;
}
</style>