<template>
  <div class="homePage">
    <div v-show="$route.meta.showfater" class="banner"></div>
    <div v-show="$route.meta.showfater" style="width:1260px;margin: auto;">
      <div class="seachBox">
        <input @keydown="calAge(event)" type="text" placeholder="请简单描述您遇到的问题" v-model="searchValue" @input="inputSearch" class="seachInput" @focus="showSearchList = true"
          @blur="blurFn" />
        <!-- @blur="showSearchList = false"  -->
        <div @click="searchInput" class="seachBtn"><img src="../../assets/seacrhIcon.png" alt="" srcset=""></div>
        <div class="showSearchList" v-if="showSearchList && searchList.length > 0 && searchValue">
          <div @click.stop="issueBtn(item,true)" class="showSearch" v-for="(item, index) in searchList" v-html="item.title">
          </div>

        </div>
      </div>
      <div class="rowBox">
        <div class="tabBox">
          <div @click="tabBtn(item)" :class="tabIndex == item.id ? 'isTab' : 'unTab'" v-for="(item, index) in tabsList">{{
            item.title }}</div>
        </div>
        <div class="issueBox">
          <div @click="issueBtn(item)" v-show="listShow || item.flag" class="row" v-for="(item, index) in helpListRst"
            :key="index" :name="index">
            <div slot="title">
              <div class="rowTitle">
                <div class="title">
                  <img class="query" src="../../assets/query.png" alt="" srcset="" />
                  {{ item.title }}
                </div>
                <img v-if="!item.flag" class="down" src="../../assets/unfold.png" alt="" srcset="" />
                <img @click.stop="closeBtn(item)" v-else class="up" src="../../assets/close.png" alt="" srcset="" />
              </div>
            </div>
            <div v-if="item.flag"
              :style="{ 'cursor': item.url ? 'pointer' : '', 'color': item.url ? '#4f77e1' : '#666666' }" class="text">
              <!-- @click="gotoUrl(item)" -->
              <div style="color: #666666" @click="btnClick($event)" v-html="item.text"></div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import {List} from './helpList'
export default {
  data() {
    return {
      activeNames: [],
      tabsList: [{
        title: '常见问题',
        id: 1
      }, {
        title: '设置相关',
        id: 2
      }, {
        title: '好友相关',
        id: 3
      }, {
        title: '加密相关',
        id: 4
      }, {
        title: '聊天/邮件相关',
        id: 5
      }],
      tabIndex: 1,
      listShow: true,
      searchValue: '',
      helpListRst: [],
      searchList: [],
      showSearchList: false,
      helpList: [
      ],
    };
  },
  mounted() {
    this.helpList = List
    // console.log(List,'List')
    document.documentElement.scrollTop = 0;
    // this.searchInput('')
    this.tabBtn({id: 1})
  },
  methods: {
    calAge(e) {
        var evt = window.event || e;
        if (evt.keyCode == 13) {
          this.searchInput()
        }
    },
    tabBtn(data) {
      this.listShow = true
      this.tabIndex = data.id
      let list = []
      this.helpList.map((item, index) => {
        item.flag = false
        if (data.id == item.classifyId) {
          list.push(item)
        }
      })
      this.helpListRst = list
    },
    getStyle(obj,attr){
     if(obj.currentStyle){//IE
        return obj.currentStyle[attr];
     }else{//ff
        return getComputedStyle(obj,false)[attr];
     }
 },
    btnClick(e) {
      let that = this
      // console.log(e,'selectTab')
      var oNav = document.getElementsByClassName('tab')
      var oNav1 = document.getElementsByClassName('tab1')
      // var nav = container.getElementById('tabBox');
      // console.log(oNav1,'oNav1')
      var oDiv = document.getElementsByClassName('wangyi')
      var oDiv1 = document.getElementsByClassName('tengxun')
      // oDiv1[0].style.display = "none"
      // oNav[0].className = 'selectTab tab';
      // var tab2 = document.getElementsByClassName('tab2')
      if(e.target.className == 'tab1'){
        // console.log(that.getStyle(oNav1[0],'display'),'display')
        oNav1[0].className = 'selectTab tab1';
        oNav[0].className = 'tab';
        // console.log(this)
        oDiv1[0].style.display = "block"
        oDiv[0].style.display = "none"
        // console.log(oNav1,'222')
      }else{
        var oNav1 = tabBox.getElementsByClassName('tab1')
        oNav[0].className = 'selectTab tab';
        oNav1[0].className = 'tab1';
        // console.log(this)
        oDiv[0].style.display = "block"
        oDiv1[0].style.display = "none"
        // console.log(oNav,'111')
      }
  //     oNav[0].onclick = function () {
  //       var oNav1 = tabBox.getElementsByClassName('tab1')
  //       oNav[0].className = 'selectTab tab';
  //       oNav1[0].className = 'tab1';
  //       // console.log(this)
  //       oDiv[0].style.display = "block"
  //       oDiv1[0].style.display = "none"
  //       console.log(oNav,'111')
  //     }
  //     oNav1[0].onclick = function () {
  // //       if(getStyle(oP,'display')==='none')
  // //  oP.style.display='block';
  // // else
  // //  oP.style.display='none';
  //       console.log(that.getStyle(oNav1[0],'display'),'display')
  //       oNav1[0].className = 'selectTab tab1';
  //       oNav[0].className = 'tab';
  //       // console.log(this)
  //       oDiv1[0].style.display = "block"
  //       oDiv[0].style.display = "none"
  //       console.log(oNav1,'222')
  //     }

    },
    blurFn() {
      setTimeout(() => {
        this.showSearchList = false

      }, 200)
    },
    issueBtn(val,flag) {
      // console.log(val,'val');
      this.tabIndex = val.classifyId
      // val.flag = true
      this.helpList.map((item, i) => {
        if (item.id == val.id) {
          item.flag = true;
          // console.log(item)
        } else {
          item.flag = false;

        }
      });
      if(flag){
        this.helpListRst = this.helpList

      }
      this.listShow = false
      this.showSearchList = false
  
    },
    answerTabBtn() {
      console.log('点击')
    },
    inputSearch(e) {
      let list = []
      let reg = new RegExp(e.target.value, "g");
      // console.log(reg);
      this.searchFn(e.target.value)
      // console.log(e.target.value)
      // console.log(this.searchFn(e.target.value))
      this.searchFn(e.target.value).map((item) => {
        let title = item.title.replace(reg, (match) => {
          return `<font style="color:#4F77E1">${match}</font>`;
        });
        list.push({ title, id: item.id, classifyId: item.classifyId })
      });
      // console.log(list, 'lists')
      this.searchList = list

    },
    searchInput() {
      // console.log(this.searchFn(this.searchValue))
      if(!this.searchValue) return
      this.tabIndex = 0
      this.listShow = true
      this.helpListRst = this.searchFn(this.searchValue)
    },
    searchFn(value) {
      let list = []
      if (null === value || undefined === value) {
        return
      } else {
        list = []; // 结果列表置空
        let regStr = '';
        // 初始化正则表达式
        for (let i = 0; i < value.length; i++) {
          regStr = regStr + '(' + value[i] + ')([\\s]*)'; //跨字匹配
        }
        let reg = new RegExp(regStr);
        // console.log(reg);
        for (let i = 0; i < this.helpList.length; i++) {
          let name = this.helpList[i].title; //按照名字匹配
          let regMatch = name.match(reg);
          // console.log(regMatch, 'regMatch')
          if (null !== regMatch) {// 将匹配的数据放入结果列表中
            list.push(this.helpList[i]);
          }
        }
        // console.log(list,'sss')
      }
      return list
    },
    closeBtn(val) {
      val.flag = false
      this.listShow = true
      // console.log(val)
    },
    gotoUrl(item) {
      if (item.url) {
        // window.location.href = item.url
        window.open(item.url, "_blank");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// @media only screen and (min-width: 1401px) {
.banner {
  // width: auto;
  width: auto;
  height: 500px;
  margin: 0 auto;
  background: url(../../assets/banner4.png) no-repeat center center;
  background-size: cover;
}

.seachBox {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 70px;
  position: relative;

}

.seachInput {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  padding-left: 30px;
  width: 400px;
  height: 48px;
  background: #FFFFFF;
  border-radius: 10px 0 0 10px;
  border: 2px solid rgba(79, 119, 225, 0.2);
  border-right: none;
}

.seachInput:focus {
  outline: none;
  box-shadow: 0px 0px 10px 4px rgba(79, 119, 225, 0.08);
}

.seachBtn {
  width: 100px;
  height: 54px;
  background: #4F77E1;
  border-radius: 0px 10px 10px 0px;

  img {
    width: 28px;
    height: 28px;
    display: block;
    margin: auto;
    margin-top: 13px;
    // align-items: center;
  }
}

.showSearchList {
  width: 430px;
  max-height: 300px;
  overflow-y: auto;
  position: absolute;
  min-height: 40px;
  right: 100px;
  top: 54px;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px 4px rgba(79, 119, 225, 0.08);
  border-radius: 0px 0px 10px 10px;
}

.showSearch {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  padding: 9px 28px;
}

// }
// @media only screen and (max-width: 1400px) {
//   .banner {
//     // width: auto;
//     width: auto;
//     height: 334px;
//     margin: 0 auto;
//     background: url(../../assets/banner4.png) no-repeat center center;
//     background-size: 100%;
//   }
// }
// .banner {
//   //   width: 1180px;
//   width: auto;
//   height: 500px;
//   margin: 0 auto;
//   background: url(../../assets/banner4.png) no-repeat center center;
//   background-size: 100% 100%;

//   //   img {
//   //     width: 100%;
//   //     height: 100%;
//   //   }
// }
.tabBox {
  width: 200px;
  margin-right: 70px;

  .unTab {
    padding: 16px 35px;
    border-radius: 8px;
    font-size: 20px;
  }

  .isTab {
    background-color: rgba(79, 119, 225, 0.1);
    padding: 16px 35px;
    color: #4F77E1;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 600;
  }
}

.rowBox {
  width: 1180px;
  margin: 70px auto;
  display: flex;
}

.issueBox {
  width: 910px;
}

.row {
  padding: 10px 20px;
  margin-bottom: 30px;
  width: 100%;
  // min-height: 70px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 4px rgba(79, 119, 225, 0.08);
  border-radius: 8px;

  .rowTitle {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
  }

  .query {
    width: 24px;
    height: 24px;
    margin-right: 16px;
  }

  .title {
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    display: flex;
    align-items: center;
  }

  .text {
    font-size: 18px;
    border-top: 1px solid #f5f5f5;
    // padding: 0px 40px 0;
    line-height: 24px;
    padding: 14px 40px 11.5px;
    margin-top: 8px;
    max-height: 600px;
    overflow: auto;
  }
  .down {
    width: 18px;
    height: 18px;
  }

  .up {
    width: 18px;
    height: 18px;
  }
}

/deep/.el-collapse {
  border: none;
}

/deep/.el-collapse-item__header {
  border-bottom: none !important;
}

/deep/.el-collapse-item__wrap {
  border-bottom: none !important;
  // height: 48px;
  // line-height: 48px;
}

/deep/.el-collapse-item__arrow {
  display: none;
}

/deep/.el-collapse-item__content {
  padding-bottom: 0;
}
</style>
<style lang="scss">
h2 {
  text-align: center;
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 33px;
}

.answerTabBox {
  width: 100%;
  height: 54px;
  display: flex;
  margin: auto;
  text-align: center;
  line-height: 54px;
  border-radius: 10px;
  border: 2px solid #4f77e1;
  overflow: hidden;
  div {
    width: 590px;
    /* height: 54px;
    // background: #ffffff;
    // border: 2px solid #4f77e1;
    // box-sizing: border-box; */
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    /* // line-height: 30px; */
  }
}

.selectTab {
  width: 590px;
  height: 54px;
  background: #4f77e1;
  font-size: 22px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff !important;
  /* // line-height: 30px; */
}

// div {
//     width: 590px;
//     /* height: 54px;
// // background: #ffffff;
// // border: 2px solid #4f77e1;
// // box-sizing: border-box; */
//     font-size: 22px;
//     font-family: PingFangSC-Regular, PingFang SC;
//     font-weight: 400;
//     color: #333333;
//     /* // line-height: 30px; */
// }

.wangyi {
  width: 100%;
  margin: 0 auto;
}

.wangyi p {
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #e02020;
  line-height: 28px;
}

.wangyi img {
  width: 100%;
  height: 100%;
}

.tengxun {
  width: 100%;
  margin: 0 auto;
  display: none;
}

.tengxun p {
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #e02020;
  line-height: 28px;
}

.tengxun img {
  width: 100%;
  height: 450px;
}
</style>