<template>
  <div class="homePage">
    <!-- <headerPage></headerPage> -->
    <div class="banner">
      <!-- <img src="../../assets/banner.png" alt="" /> -->
      <div class="downTypeBox">
        <div class="downType">
          移动端下载
          <img v-on:mouseover="changeActive($event, 'Android')" src="../../assets/android.png" alt=""
            v-if="androidmoveFlag" />
          <img v-on:mouseout="removeActive($event)" v-if="!androidmoveFlag" class="qrsk"
            style="width: 160px; height: 160px" src="../../assets/androiddownload.png" alt="" srcset="" />

          <img v-on:mouseover="changeActive($event, 'IOS')" src="../../assets/ios.png" alt="" v-if="iosmoveFlag" />
          <img v-on:mouseout="removeActive($event)" v-if="!iosmoveFlag" class="qrsk" style="width: 160px; height: 160px"
            src="../../assets/iosdownload.png" alt="" srcset="" />
        </div>
        <div class="downType">
          PC端下载
          <img class="windows" style="margin-left: 38px" src="../../assets/windows.png" alt=""
            @click="downPc(webDownLoadInfo.winUrl, 'WIN')" />
          <img class="mac" src="../../assets/macos.png" alt=""
            @click="downPc(webDownLoadInfo.macUrl, 'MAC')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import headerPage from '@/components/header.vue'
import { downloadNum,getWebDownLoadInfo } from '@/config/api'
export default {
  data() {
    return {
      androidmoveFlag: true,
      iosmoveFlag: true,
      moveFlag: true,
      timeoutFlag: null,
      webDownLoadInfo:null
    };
  },
  mounted() {
    document.documentElement.scrollTop = 0;
    this.getWebDownLoadInfo()
  },
  methods: {
    // 鼠标移入
    changeActive(e, name) {
      // console.log(e);
      if (name == "Android") {
        this.androidmoveFlag = false;
      } else {
        this.iosmoveFlag = false;
      }
      // console.log();
      // 更改div的显示属性
      this.moveFlag = false;
      this.versionTotal = true;
      // 给需要设置的内容赋值
      // this.appVersionName = data.appVersion;
      // X和Y坐标需要减去一部分像素，是为了和鼠标更接近，具体像素可以调整
      this.floatLeft = e.clientX - 180 + "px"; // 减去外层div的left距离
      this.floatTop = e.clientY - 55 + "px";
      this.timeoutFlag = setTimeout(() => {
        console.log(name)
        downloadNum({ platform: name }).then(res => {

        })
      }, 5000)
    },
    // 鼠标移出
    removeActive(e) {
      // 设置不显示
      clearTimeout(this.timeoutFlag)
      this.moveFlag = true;
      this.androidmoveFlag = true;
      this.iosmoveFlag = true;
      // console.log(e);

      this.versionTotal = false;
    },
    downPc(e, downType) {
      window.location.href = e
      downloadNum({ platform: downType }).then(res => {

      })
    },
    getWebDownLoadInfo(){
      getWebDownLoadInfo().then(res => {
        this.webDownLoadInfo = res.data
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.windows:hover {
  // cursor: pointer;
  scale: 1.1;
}

.mac:hover {
  scale: 1.1;
  // cursor: pointer;


}

// @media screen and (max-width: 992px) {
//   .homePage {
//     width: 177vh;
//     margin: 0 auto;
//   }
// }
// @media only screen and (min-width: 1401px) {
.banner {
  // width: auto;
  width: auto;
  height: 756px;
  margin: 0 auto;
  background: url(../../assets/banner31.png) no-repeat center center;
  background-size: cover;
  // position: relative;
}

// }
@media only screen and (max-width: 1700px) {

  //   .banner {
  //     // width: auto;
  //     width: auto;
  //     height: 494px;
  //     margin: 0 auto;
  //     background: url(../../assets/banner31.png) no-repeat center center;
  //     background-size: 100%;
  //     position: relative;
  //   }
  /deep/.downTypeBox {
    position: absolute !important;
    right: 102px !important;
    top: 250px !important;
    color: #ffffff !important;
  }
}

// .banner {
//   //   width: 1180px;
//   width: auto;
//   height: 756px;
//   margin: 0 auto;
//   background: url(../../assets/banner31.png) no-repeat center center;
//   background-size: 100% 100%;
//   position: relative;

//   //   img {
//   //     width: 100%;
//   //     height: 100%;
//   //   }
// }
.downTypeBox {
  position: absolute;
  right: 380px;
  top: 250px;
  color: #ffffff;

  .downType {
    display: flex;
    align-items: center;
    margin-top: 40px;
    font-size: 24px;

    img {
      width: 160px;
      height: 44px;
      margin-left: 20px;
      cursor: pointer;

    }
  }
}

// .downType img:hover{
//   // img {
//   //     width: 160px;
//   //     height: 44px;
//   //     margin-left: 20px;
//       display: none;
//     // }
// }
.characteristics {
  width: 1200px;
  margin: auto;
  text-align: center;

  .characteristicsList {
    display: flex;
    margin-bottom: 66px;

    .item {
      width: 280px;
      height: 410px;
      padding: 60px 30px 0;

      h3 {
        letter-spacing: 2px;
        line-height: 1;
        margin: 40px 0 32px;
      }

      img {
        width: 80px;
        height: 80px;
      }

      p {
        font-size: 18px;
        font-weight: 400;
        color: #666666;
        letter-spacing: 2px;
        text-align: justify;
        line-height: 24px;
        margin-bottom: 20px;
      }
    }

    .item:hover {
      background: #ffffff;
      box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.08);
      border-radius: 16px;
    }
  }
}

.qrsk {
  width: 160px;
  height: 160px;
  margin-top: -116px;
}
</style>