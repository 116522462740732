import Vue from 'vue'
import Router from 'vue-router'
import HelloWorld from '@/components/HelloWorld'
import Home from "@/views/home";
import Sikong from "@/views/sikong";
import Downloadpage from "@/views/downloadpage"
import Help from "@/views/help"
import About from "@/views/about"
import PayMember from "@/views/payMember"
import PayPage from "@/views/payPage"
// import GetAuthorizationCode from "@/views/help/getAuthorizationCode"

import Header from "@/components/header.vue"
Vue.use(Router)

export default new Router({
    mode: 'hash',
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home,
        },
        {
            path: '/sikong',
            name: 'Sikong',
            component: Sikong,
        },
        {
            path: '/downloadpage',
            name: 'Downloadpage',
            component: Downloadpage,
        },
        {
            path: '/payMember',
            name: 'PayMember',
            component: PayMember,
        },
        
        {
            path: '/help',
            name: 'Help',
            component: Help,
            meta:{
                showfater: true
             },
            // children: [{
            //     path: 'getAuthorizationCode',
            //     name: 'GetAuthorizationCode',
            //     component: GetAuthorizationCode,
            //     meta:{
            //         showfater: false
            //      }
            // }]
        },
        {
            path: '/about',
            name: 'About',
            component: About
        },
        {
            path: '/payPage',
            name: 'PayPage',
            component: PayPage
        },
    ]
})

const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error => error)
}
