<template>
  <div class="homePage" ref="content">
    <!-- <headerPage></headerPage> -->
    <div class="banner">
      <!-- <img src="../../assets/banner.png" alt="" /> -->
    </div>
    <div class="characteristics">
      <h1 style="margin: 60px 0 40px; line-height: 1; text-align: center">
        公司简介
      </h1>
      <p
        style="
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 27px;
        "
      >
        浙江启明量子信息技术有限公司是一家致力于保护用户数据和隐私安全的信息技术企业。利用量子真随机密钥和密码学加密算法，为用户提供端到端安全解决方案。主张通过建设、运营量子密钥云，构建“数据与密钥分离”的互联网新型生态体系，帮助用户真正实现“我的数据，我做主”。
      </p>
      <div class="infoList">
        <div class="item" v-for="(item, index) in infoList" :key="index">
          <img class="mission" :src="item.image" alt="" />
          <div>
            <h2>{{ item.title }}</h2>
            <img
              class="linedot"
              src="../../assets/linedot.png"
              alt=""
              srcset=""
            />
            <p :style="{ 'margin-bottom': index == 1 ? '38px' : '60px', 'line-height': index==1? '22px':'16px' }" >
              {{ item.text }}
            </p>
          </div>
        </div>
      </div>
      <h1>资质认证</h1>
      <div class="certification" style="width:820px;  padding: 0 190px;">
        <div>
        <img style="width:100%" src="../../assets/dengbao.png" alt="">
          <p>等保二级证书</p>
        </div>
      </div>
      <div class="certification" style="width: 70%;
    margin: auto;">
        <div class="row">
        <div>
          <img src="../../assets/skruanzhu.png" alt="" />
          <p>增值电信业务经营许可证</p>
        </div>
        
        <div>
          <img src="../../assets/sikong.png" alt="" />
          <p>司空软著证书</p>
        </div>
       
      </div>
        <div class="row">
          <div>
          <img src="../../assets/shengke.png" alt="" />
          <p>省科小证书</p>
        </div>
       
        <div>
          <img src="../../assets/wuyoumiyou.png" alt="" />
          <p>无忧密邮软著证书</p>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import headerPage from '@/components/header.vue'
export default {
  data() {
    return {
      infoList: [
        {
          image: require("../../assets/mission.png"),
          title: "使命",
          text: "保护我们的数据安全，守护我们的无形资产",
        },
        {
          image: require("../../assets/vision.png"),
          title: "愿景",
          text: "构建“数据与密钥分离”的互联网新型生态体系，努力成为全球领先的密钥服务商",
        },
      ],
    };
  },
  mounted() {
    document.documentElement.scrollTop = 0;

    if (this.$store.state.anchorInfo.flag) {
      this.tabshandleClick(this.$store.state.anchorInfo);
    }
  },
  computed: {
    anchorInfo() {
      return this.$store.state.anchorInfo;
    },
  },
  watch: {
    anchorInfo(val) {
      this.tabshandleClick(val);
    },
  },

  methods: {
    handleScroll() {
      let scrollTop = this.$refs.content.scrollTop;
      let blocks = document.querySelectorAll(".characteristics");
      blocks.forEach((item, index) => {
        // console.log("blocks", item.id);
        if (scrollTop >= item.offsetTop) {
          this.activeTabs = item.id;
        }
      });
    },
    tabshandleClick(data) {
      // console.log("data", data);
      let index = Number(data.index);
      let blocks = document.querySelectorAll(".characteristics");
      let step = 20; //滚动步长
      let currentScrollTop = this.$refs.content.scrollTop;
      // console.log("blocks", blocks);
      let targetOffsetTop = blocks[index].offsetTop;
      // console.log("currentScrollTop", currentScrollTop);
      // console.log("targetOffsetTop", targetOffsetTop);
      if (currentScrollTop > targetOffsetTop) {
        const smoothUp = () => {
          if (currentScrollTop >= targetOffsetTop) {
            if (currentScrollTop - targetOffsetTop >= step) {
              currentScrollTop -= step;
              setTimeout(smoothUp, 1);
            } else {
              currentScrollTop = targetOffsetTop - step;
            }
            document.documentElement.scrollTop = currentScrollTop;
          }
        };
        smoothUp();
      } else {
        const smoothDown = () => {
          if (currentScrollTop <= targetOffsetTop) {
            // 如果和目标相差距离大于等于step 就跳 step
            if (targetOffsetTop - currentScrollTop >= step) {
              currentScrollTop += step;
              setTimeout(smoothDown, 1);
            } else {
              // 否则直接跳到目标点，防止跳过
              currentScrollTop = targetOffsetTop - step;
            }
            document.documentElement.scrollTop = currentScrollTop;
            // console.log(this.$refs.content.scrollTop);
          }
        };
        smoothDown();
      }
      this.$store.commit("setFlag", false);
    },
  },
};
</script>

<style lang="scss" scoped>
// @media screen and (max-width: 992px) {
//   .homePage {
//     width: 177vh;
//     margin: 0 auto;
//   }
// }
// @media only screen and (min-width: 1401px) {
.banner {
  // width: auto;
  width: auto;
  height: 500px;
  margin: 0 auto;
  background: url(../../assets/banner5.png) no-repeat center center;
  background-size: cover;
}
// }
// @media only screen and (max-width: 1400px) {
//   .banner {
//     // width: auto;
//     width: auto;
//     height: 334px;
//     margin: 0 auto;
//     background: url(../../assets/banner5.png) no-repeat center center;
//     background-size: 100%;
//   }
// }
// .banner {
//   //   width: 1180px;
//   width: auto;
//   height: 500px;
//   margin: 0 auto;
//   background: url(../../assets/banner5.png) no-repeat center center;
//   background-size: 100% 100%;
//   //   img {
//   //     width: 100%;
//   //     height: 100%;
//   //   }
// }
.characteristics {
  width: 1200px;
  margin: auto;
  .infoList {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    .item {
      display: flex;
      align-items: center;
      padding: 0 20px;
      width: 540px;
      height: 166px;
      background: #ffffff;
      box-shadow: 0px 0px 10px 4px rgba(79, 119, 225, 0.08);
      border-radius: 8px;
      .mission {
        width: 60px;
        height: 60px;
        margin-right: 20px;
      }
      .linedot {
        width: 30px;
        height: 6px;
      }
      h2 {
        line-height: 1px;
        margin-top: 40px;
        font-size: 24px;
      }
      p {
        line-height: 16px;
        margin-bottom: 60px;
        margin-top: 18px;
        font-size: 18px;
        letter-spacing: 2px;
      }
    }
  }
  h1 {
    text-align: center;
    margin: 64px 0 34px;
  }
  .certification {
    display: flex;
    justify-content: space-between;
    text-align: center;
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    p{
    color: #333333;

    }
    img {
      width: 380px;
      height: 518px;
    }
  }
}
</style>