import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    anchorInfo:{
      class: '',
      index: 0,
      flag: false
    }
   
  },
  mutations: {
    setClass(state,option){
      state.anchorInfo={...option}
     // console.log(state)
    },
    setFlag(state,option){
      state.anchorInfo.flag = option
    }
  },
  modules: {
  },
  // getters
});

export default store