import axios from '../utils/axios.js'

export const feedBack = (data) => { 
   return axios({
       method:'post',//请求方式
       url:"/user/page/userFeedBack",//接口路径
       data:data,
       headers: {
        'content-type':"multipart/form-data" 
       }
   });
}

export const feedBackImage = (data) => { 
    return axios({
        method:'post',//请求方式
        url:"/user/page/userFeedBackWithPic",//接口路径
        data:data,
        headers: {
         'content-type':"multipart/form-data" 
        }
    });
 }

 //统计下载
 export const downloadNum = (data) => { 
    return axios({
        method:'post',//请求方式
        url:"/statistics/web/download",//接口路径
        data:data,
        headers: {
         'content-type':"multipart/form-data" 
        }
    });
 }

 // 获取验证码
 export const getWebsiteRequireCode = (data) => { 
    return axios({
        method:'get',//请求方式
        url:"/user/official/websiteRequireCode",//接口路径
        params:data,
        // headers: {
        //  'content-type':"multipart/form-data" 
        // }
    });
 }

 // 获取会员信息
 export const getVipCodeInfo = (data) => { 
    return axios({
        method:'get',//请求方式
        url:"/user/official/getVipCodeInfo",//接口路径
        params:data,
        // headers: {
        //  'content-type':"multipart/form-data" 
        // }
    });
 }

// 获取会员信息
export const getZFBPaymentQRCode = (data) => { 
    return axios({
        method:'get',//请求方式
        url:"/qrcode/official/getZFBPaymentQRCode",//接口路径
        params:data,
        // headers: {
        //  'content-type':"multipart/form-data" 
        // }
    });
 }

// 获取会员信息
export const getWXPaymentQRCode = (data) => { 
    return axios({
        method:'get',//请求方式
        url:"/qrcode/official/getWXPaymentQRCode",//接口路径
        params:data,
        // headers: {
        //  'content-type':"multipart/form-data" 
        // }
    });
 }

 // 获取会员信息
export const getwxQueryOrder = (data) => { 
    return axios({
        method:'get',//请求方式
        url:"/qrcode/official/wxQueryOrder",//接口路径
        params:data,
        // headers: {
        //  'content-type':"multipart/form-data" 
        // }
    });
 }


  // 获取会员信息
export const getzfbQueryOrder = (data) => { 
    return axios({
        method:'get',//请求方式
        url:"/qrcode/official/zfbQueryOrder",//接口路径
        params:data,
        // headers: {
        //  'content-type':"multipart/form-data" 
        // }
    });
 }

   // 获取会员信息
export const getWebDownLoadInfo = (data) => { 
    return axios({
        method:'get',//请求方式
        url:"product/getWebDownLoadInfo",//接口路径
        params:data,
        // headers: {
        //  'content-type':"multipart/form-data" 
        // }
    });
 }