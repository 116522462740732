<template>
  <div class="footerPage">
    <div class="footer">
      <div class="footerleft">
        <div class="headlogo">
          <img src="../assets/logosk.png" alt="" />
          <div>
            <p>司空</p>
            <p>用司空 更安心</p>
          </div>
        </div>
        
      </div>
      <div class="footerright">
        <div class="header">
          <p @click="gotoURl('sikong')">司空</p>
          <p @click="gotoURl('about')">公司</p>
          <p @click="gotoURl('help')">帮助</p>
        </div>
        <div class="footerdown">
          <div>
            <p @click="gotoAnchor('sikong','.dataInfo',0)">文件加密</p>
            <p @click="gotoAnchor('sikong','.dataInfo',1)">邮件加密</p>
            <p @click="gotoURl('downloadpage')">下载中心</p>
          </div>
          <div>
            <p @click="gotoAnchor('about','.characteristics',0)">关于我们</p>
            <p @click="gotoWindowsUrl('../../../protocol.html')">用户协议</p>
            <p @click="gotoWindowsUrl('../../../miyou_privacy.html')">
              隐私政策
            </p>
          </div>
        </div>
      </div>
      <div class="footQr">
        <div>
          <img class="qrsk" src="../assets/qrsk.png" alt="" />
          <p>微信公众号</p>
        </div>
        <div>
          <img class="dyqr" src="../assets/dyqr.png" alt="" />
          <p>抖音二维码</p>
        </div>
        </div>
    </div>
    <p class="footertips">
      Copyright © 2020 浙江启明量子信息技术有限公司 All Rights Reserved
     <span style="cursor: pointer;" @click="gotoUrl('https://beian.miit.gov.cn/')">浙ICP备20001430号-1</span>  <span style="cursor: pointer;" @click="gotoUrl('https://www.beian.gov.cn/')">浙公网安备 33011302000241号</span> 
    </p>
  </div>
</template>

<script>
export default {
  methods: {
    gotoURl(item) {
      this.$router.push({
        path: item,
      });
    },
    gotoWindowsUrl(item) {
      // window.location.href = item
      window.open(item, "_blank");
    },
    gotoAnchor(path,item,index) {
      this.$router.push({
        path: path,
      });
     // console.log(this)
      this.$store.commit('setClass',{
        class:item,
        index,
        flag: true
      })
    },
    gotoUrl(data){
      window.open(data, "_blank");
    }
  },
};
</script>

<style lang="scss" scoped>
.footerPage {
  width: 100%;
  height: 100%;
  background: #f7f9fa;
  padding: 30px 0 0 0;
  // margin: 74px 0 0 0 ;
}
.footer {
  display: flex;
  width: 1180px;
  justify-content: space-between;
  margin: 0 auto;
}
.footerleft {
  display: flex;
  .headlogo {
    display: flex;
    align-items: center;
    line-height: 0.7;
    img {
      width: 44px;
      height: 44px;
      margin-right: 20px;
    }
    p:nth-child(1) {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
    }
    p:nth-child(2) {
      font-size: 12px;
      font-weight: 400;
      color: #333333;
    }
  }
 
}
.footQr {
    display: flex;
    align-items: center;
    margin-top: 20px;
    img {
      width: 80px;
      height: 80px;
      margin-right: 14px;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }
  }
  .qrsk:hover{
    transform: scale(2,2);
  }
  .dyqr:hover{
    transform: scale(2,2);
  }
.footerright {
  margin-left: 180px;
  .header {
    display: flex;
    p {
      margin-right: 172px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }
  }
  .footerdown {
    display: flex;
    p {
      margin-right: 144px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      cursor: pointer;
    }
  }
}
.footertips {
  width: 1180px;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  text-align: center;
  margin: 40px auto 10px;
  margin-bottom: 0;
  padding-bottom: 10px;
}
// p:hover {
//   cursor: pointer;
// }
</style>