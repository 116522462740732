<template>
  <div class="homePage">
    <!-- <div @click="tabshandleClick">1111111</div> -->
    <!-- <headerPage></headerPage> -->
    <div class="banner">
      <!-- <img src="../../assets/banner.png" alt="" /> -->
    </div>
    <div class="characteristics">
      <h1 style="margin: 60px 0 40px; line-height: 1; color: #333333">技术特点</h1>
      <div class="characteristicsList" ref="content">
        <div class="item" v-for="(item, index) in characteristicsList" :key="index">
          <img :src="item.image" alt="" />
          <h2>{{ item.title }}</h2>
          <p>{{ item.memo }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

// import headerPage from '@/components/header.vue'
export default {
  data() {
    return {
      characteristicsList: [
        {
          image: require("../../assets/one.png"),
          title: "量子真随机密钥",
          memo: "基于量子力学原理生成量子密钥，通过NIST真随机验证测试，具有无法预测、永不重复、完全随机的特性，密钥质量高。",
        },
        {
          image: require("../../assets/two.png"),
          title: "数据与密钥分离",
          memo: "作为密钥服务商，仅提供量子密钥加解密服务，不参与用户数据的传输和存储，且数据的加解密均在用户本地完成，从而实现数据与密钥分离，只有用户可以同时获取数据和密钥。",
        },
        {
          image: require("../../assets/three.png"),
          title: "一次一密",
          memo: "丰富的量子密钥资源可为每一条信息、每一份文件匹配不同的量子密钥，独一无二，永不重复。",
        },
        {
          image: require("../../assets/four.png"),
          title: "全程加密",
          memo: "采用端到端加密技术，确保用户数据在网络传输、中转和存储全程处于强加密状态，任何第三方都无机可乘。",
        },
      ],
    };
  },
  mounted() {
    document.documentElement.scrollTop = 0;

    this.$refs.content.onscroll = () => {
      this.handleScroll();
    };
    // console.log(this.$refs.content);
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
// @media screen and (max-width: 992px) {
//   .homePage {
//     width: 177vh;
//     margin: 0 auto;
//   }
// }
// @media only screen and (min-width: 1401px) {
.banner {
  // width: auto;
  width: auto;
  height: 800px;
  margin: 0 auto;
  background: url(../../assets/banner.png) no-repeat center center;
  background-size: cover;
}

// }
// @media only screen and (max-width: 1400px) {
// .banner {
//   // width: auto;
//   width: auto;
//   height: 522px;
//   margin: 0 auto;
//   background: url(../../assets/banner.png) no-repeat center center;
//   background-size: 100%;
// }
// }
// @media only screen and (max-width: 640px) {
//   .banner {
//     height: 800px;
//   }
// }
.characteristics {
  width: 1200px;
  margin: auto;
  text-align: center;

  .characteristicsList {
    display: flex;
    margin-bottom: 66px;

    .item {
      width: 280px;
      height: 410px;
      padding: 60px 30px 0;

      h3 {
        letter-spacing: 2px;
        line-height: 1;
        margin: 40px 0 32px;
      }

      img {
        width: 80px;
        height: 80px;
      }

      p {
        font-size: 18px;
        font-weight: 400;
        color: #666666;
        letter-spacing: 2px;
        text-align: justify;
        line-height: 24px;
        margin-bottom: 20px;
      }
    }

    .item:hover {
      background: #ffffff;
      box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.08);
      border-radius: 16px;
    }
  }
}
</style>
