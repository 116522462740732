<template>
  <div class="functionPage">
    <img
      style="width: 98px; height: 98px"
      @click="feekback"
      src="../assets/feekback.png"
      alt=""
      srcset=""
    />
    <img
      style="width: 98px; height: 98px"
      v-if="btnFlag"
      @click="gotop"
      src="../assets/gotop.png"
      alt=""
      srcset=""
    />

    <el-dialog title="" :show-close="true" :visible.sync="dialogVisible" width="740px" :before-close="handleClose">
      <h3 style="text-align: center">意见反馈</h3>
      <div class="lable">反馈分类</div>
      <div class="typeList">
        <div
          class="feekType"
          :class="selectIndex == index ? 'feekTypeSelect' : ''"
          @click="typeBtn(item, index)"
          v-for="(item, index) in typeList"
          :key="index"
        >
          <div>{{ item.type }}</div>
        </div>
      </div>
      <div class="lable">反馈内容</div>
      <div>
        <el-input
          type="textarea"
          :rows="4"
          placeholder="请输入10字以上的问题描述以便我们为您提供更好帮助"
          v-model="form.feedBack"
          maxlength="200"
          show-word-limit
          background="#DFDFDF"
          style="margin-bottom: 10px"
        >
        </el-input>
        <el-upload
          action="#"
          list-type="picture-card"
          :auto-upload="false"
          :on-change="handleSuccess"
          ref="upload"
        >
          <i slot="default" class="el-icon-plus"></i>
          <div slot="file" slot-scope="{ file }">
            <img
              class="el-upload-list__item-thumbnail"
              :src="file.url"
              alt=""
            />
            <span class="el-upload-list__item-actions">
              <!-- <span
                class="el-upload-list__item-preview"
                @click="handlePictureCardPreview(file)"
              >
                <i class="el-icon-zoom-in"></i>
              </span>
              <span
                v-if="!disabled"
                class="el-upload-list__item-delete"
                @click="handleDownload(file)"
              >
                <i class="el-icon-download"></i>
              </span> -->
              <span
                v-if="!disabled"
                class="el-upload-list__item-delete"
                @click="handleRemove(file)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </el-upload>
      </div>
      <div class="lable">联系方式</div>
      <div>
        <el-input
          :rows="2"
          placeholder="请输入您的手机号/邮箱地址便于联系（必填）"
          v-model="form.phone"
          @input="inPhoneNum"
        >
        </el-input>

        <!-- <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog> -->
      </div>
      <div class="btn" @click="submitForm" v-if="isSubmit">
        <img src="../assets/yesBtn.png" alt="" />
      </div>
      <div class="btn" v-else>
        <img src="../assets/noBtn.png" alt="" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { feedBack, feedBackImage } from "@/config/api";
export default {
  data() {
    return {
      btnFlag: true,
      disabled: false,
      dialogImageUrl: "",
      dialogVisible: false,
      typeList: [
        {
          type: "闪退/崩溃",
        },
        {
          type: "会员/账号",
        },
        {
          type: "功能使用异常",
        },
        {
          type: "功能使用建议",
        },
        {
          type: "其他",
        },
      ],
      form: {
        feedBack: "",
        // text: "",
        phone: "",
        type: "",
        file: [],
      },
      selectIndex: 0,
      fileList: [],
      formData: new FormData(),
      imageFile: null,
      isSubmit: false
    };
  },
  mounted() {
    this.form.type = this.typeList[0].type;
    window.addEventListener("scroll", this.scrollToTop);
    // this.getImageFileFromUrl(
    //   "http://192.168.2.80:8080/img/logo.4eb5ae8d.png",
    //   "logo.4eb5ae8d.png"
    // )
    //   .then((response) => {
    //     console.log(response);
    //     // 返回的是文件对象，使用变量接收即可
    //     this.imageFile = response;
    //   })
    //   .catch((e) => {
    //     console.error(e);
    //   });
  },
  methods: {
    feekback() {
      // console.log(111);
      this.dialogVisible = true;
    },
    typeBtn(item, index) {
      this.selectIndex = index;
      this.form.type = item.type;
    },
    handleClose() {
      this.dialogVisible = false;

    },
    handleSuccess(file, fileList) {
      // console.log(file, fileList);
      // console.log(JSON.stringify(file.raw));
      this.fileList = fileList;
      // console.log(this);

      // this.form.file.push(file.raw);
      // this.formData.append("file", file.raw);
    },
    inPhoneNum(e) {
      console.log(e)
      if (e) {
        if(e.indexOf("@") == -1){
        var phone = e.replace(/\s/g, ""); //去除空格
        //校验手机号，号段主要有(不包括上网卡)：130~139、150~153，155~159，180~189、170~171、176~178。14号段为上网卡专属号段
        let regs = /^((13[0-9])|(17[0-1,6-8])|(15[^4,\\D])|(18[0-9]))\d{8}$/;
       
        if (!regs.test(phone)) {
          // this.$message({
          //   message: "手机号不正确",
          //   type: "warning",
          // });
         this.isSubmit = false
         return false
        } else {
          // delete this.form.type;
         this.isSubmit = true
         return true
          // if (!this.fileList.length) {
          //   this.formData.set("file", this.imageFile);
          // }
          // 带图片
        }
        
        }else{
        var mail = e.replace(/\s/g, ""); //去除空格
           var strRegex = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
            if(!strRegex.test(mail)){
              this.isSubmit = false
         return false
            }else{
               this.isSubmit = true
         return true
              
            }
          }
        }else{
         this.isSubmit = false
         return false
        }
      
    },
    submitForm() {
      if (!this.inPhoneNum){
return
      } 
      if (this.form.phone.length) {
        // var phone = this.form.phone.replace(/\s/g, ""); //去除空格
        // //校验手机号，号段主要有(不包括上网卡)：130~139、150~153，155~159，180~189、170~171、176~178。14号段为上网卡专属号段
        // let regs = /^((13[0-9])|(17[0-1,6-8])|(15[^4,\\D])|(18[0-9]))\d{8}$/;
        // if (!regs.test(phone)) {
        //   this.$message({
        //     message: "手机号不正确",
        //     type: "warning",
        //   });
        // } else 
        if (!this.form.feedBack.length) {
          this.$message({
            message: "请填写反馈内容",
            type: "warning",
          });
        } else {
          // delete this.form.type;
          this.formData.set("feedBack", this.form.type+'|'+this.form.feedBack);
          this.formData.set("phone", this.form.phone);
          // this.formData.append('file',this.form.file)
          this.fileList.map((item, index) => {
            this.formData.append("file", item.raw);
          });
          // if (!this.fileList.length) {
          //   this.formData.set("file", this.imageFile);
          // }
          // 带图片

          if (this.fileList.length) {
            // console.log(this.formData.get('file'))
            feedBackImage(this.formData).then((res) => {
               this.$message({
                message: "反馈成功！",
                type: "success",
              });
         this.isSubmit = false

              this.formData = new FormData();
              (this.form = {
                feedBack: "",
                // text: "",
                phone: "",
                type: "",
                file: [],
              }),
                (this.fileList = []);
              this.$refs.upload.clearFiles()((this.dialogVisible = false));
            });
          } else {
            //不带图片
            feedBack(this.formData).then((res) => {
              // console.log(res);
              this.$message({
                message: "反馈成功！",
                type: "success",
              });
         this.isSubmit = false
              this.formData = new FormData();
              (this.form = {
                feedBack: "",
                // text: "",
                phone: "",
                type: "",
                file: [],
              }),
                (this.fileList = []);
              this.$refs.upload.clearFiles()((this.dialogVisible = false));
            });
          }
        }
        // console.log(this.form);
        // console.log("submit!");
      } else {
        this.$message({
          message: "请填写手机号或者邮箱！",
          type: "warning",
        });
      }
    },
    handleRemove(file) {
      // console.log(file);
      this.fileList.map((item, index) => {
        if (file.uid == item.uid) {
          this.fileList.splice(index, 1);
        }
      });
    },
    handlePictureCardPreview(file) {
      // console.log(file);
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    handleDownload(file) {
      // console.log(file);
    },
    gotop() {
      const that = this;
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed;
        if (that.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
    },
    scrollToTop() {
      const that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (that.scrollTop > 60) {
        that.btnFlag = true;
      } else {
        that.btnFlag = false;
      }
    },
    /**
     * 根据图片url转为png文件对象
     * @param url
     * @param imageName
     * @returns {Promise<unknown>}
     */
    getImageFileFromUrl(url, imageName) {
      return new Promise((resolve, reject) => {
        var blob = null;
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.setRequestHeader("Accept", "application/json, text/plain, */*");
        xhr.responseType = "blob";
        // 加载时处理
        xhr.onload = () => {
          // 获取返回结果
          blob = xhr.response;
          let imgFile = new File([blob], imageName, { type: "image/png" });
          // 返回结果
          resolve(imgFile);
        };
        xhr.onerror = (e) => {
          reject(e);
        };
        // 发送
        xhr.send();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.functionPage {
  z-index: 99999999;
  position: fixed;
  right: 86px;
  bottom: 250px;
  img {
    display: block;
    width: 98px;
    height: 98px;
  }
  /deep/.el-radio-group {
    display: flex !important;
  }
}
.typeList {
  display: flex;
}
.lable {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
  margin: 20px 0 10px;
}
.feekType {
  padding: 6px;
  border: solid 1px #dfdfdf;
  border-radius: 6px;
  margin-right: 20px;
}
.feekTypeSelect {
  // background: #f8f8f8;
  background-color: rgba(79, 119, 225, 1);
  color: white;
  border: none;
}
/deep/.el-textarea__inner {
  background: #f8f8f8 !important;
}
/deep/.el-input__inner {
  background: #f8f8f8 !important;
}
.btn {
  width: 240px;
  height: 36px;
  margin: 30px auto 0;
  img {
    width: 100%;
    height: 100%;
  }
}
/deep/.el-dialog__body {
  padding-top: 0 !important;
}
/deep/.el-upload--picture-card {
  width: 56px;
  height: 56px;
  line-height: 62px;
}
/deep/.el-upload-list--picture-card .el-upload-list__item {
  width: 56px;
  height: 56px;
}
.functionPage img {
  width: 100%;
  height: 100%;
}
</style>