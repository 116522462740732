/*
 * @Author: 李双成 996330382@qq.com
 * @Date: 2023-06-28 16:21:58
 * @LastEditors: 李双成 996330382@qq.com
 * @LastEditTime: 2023-07-10 11:35:57
 * @FilePath: \sikong-web\src\views\help\helpList.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export const List = [
  // {
  //   title: "文件通过司空加密后分享给好友，好友为什么无法打开？",
  //   text: "文件加密后必须先分配密钥权限给好友再分享，好友才能对文件进行解密阅览。即分享加密文件的同时，也要分享密钥权限给对方。",
  //   flag: false,
  //   classifyId: 5,
  //   id: 1
  // },
  // {
  //   title: "如何进行好友的授权操作？",
  //   text: "选中加密文件，找到授权按钮，分配好友权限。但记得司空里的文件都是一次一密，你解密再加密后的权限也需要重新分配。",
  //   flag: false,
  //   classifyId: 1,
  //   id: 2
  // },
  // {
  //   title: "盒子里的文件都可以进行量子密钥加密，为何还需要设置盒子的6位进入密码？",
  //   text: "设置6位密码是为了保护用户手机短时不受控情况下的数据安全，量子密钥加密是为了防止非法访问者通过技术手段暴力破解，从而为用户的数据和隐私安全提供双重保护。",
  //   flag: false,
  //   classifyId: 1,
  //   id: 3
  // },
  {
    title: "如何获取个人邮箱授权码？",
    index: 1,
    text: `<div class="answerTabBox" id="tabBox">
      <div class="tab selectTab">
          163/126邮箱授权码获取
      </div>
      <div class="tab1">
          QQ邮箱授权码获取
      </div>
  </div>
  <div class="wangyi">
      <p>第一步：登录163邮箱，进入设置界面，如图：</p>
      <img src="./163/firstStep.png" alt="" srcset="" />
      <p>第二步：点击IMAP/SMTP服务“开启”按钮，如图：</p>
      <img src="./163/theSecondStep.png" alt="" srcset="" />
      <p>第三步：点击“开启”后弹出如下弹窗，点击“继续开启”，如图：</p>
      <img src="./163/theThirdStep.png" alt="" srcset="" />
      <p>第四步：发送短信验证，短信发送成功后，点击“我已发送”，如图</p>
      <img src="./163/stepFour.png" alt="" srcset="" />
      <p>第五步：生成授权码，如图：</p>
      <img src="./163/StepFive.png" alt="" srcset="" />
  </div>
  <div class="tengxun">
      <p>第一步：登录QQ邮箱，点击“设置”，进入“账户”界面，如图：</p>
      <img src="./qq/firstStep.png" alt="" srcset="" />
      <p>第二步：点击IMAP/SMTP服务“开启”按钮，如图：</p>
      <img src="./qq/theSecondStep.png" alt="" srcset="" />
      <p>第三步：发送短信验证，短信发送成功后，点击“我已发送”，如图：</p>
      <img src="./qq/theThirdStep.png" alt="" srcset="" />
      <p>第四步：生成授权码，如图：</p>
      <img src="./qq/stepFour.png" alt="" srcset="" />
  </div>`,
    flag: false,
    url: "../../../getAuthorizationCode.html",
    classifyId: 1,
    id: 1
  },
  // {
  //   title: "其他邮箱如何设置服务器地址及端口号？",
  //   text: "点击查看详情",
  //   flag: false,
  //   url: "../../../other.html",
  //   classifyId: 1,
  //   id: 5
  // },
  {
    title: "司空如何收取全部邮件？",
    index: 2,
    text: ` 
  <p style="width: 176px;
  height: 30px;
  font-size: 22px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 30px;">以163邮箱示例：</p>
  <p style="

  font-size: 22px;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #333333;
  ">邮箱服务商默认设置终端可收取最近30天邮件，如需收取所有历史邮件，请在PC端登录邮箱并更改设置。</p>
  <div class="wangyi">
      <p>第一步：登录163邮箱，点击“设置”，点击“POP3/SMTP/IMAP”，如图：</p>
      <img src="./howAll/firstStep.png" alt="" srcset="" />
      <p>第二步：将“收取选项”的默认设置更改为“收取全部邮件”，如图：</p>
      <img src="./howAll/theSecondStep.png" alt="" srcset="" />
</div>`,
    flag: false,
    url: "../../../howAll.html",
    classifyId: 1,
    id: 2
  },
  {
    title: "收不到消息和邮件怎么办？",
    index: 3,
    text: `<p style="
    line-height: 34px;

font-size: 18px;
font-family: PingFangSC-Semibold, PingFang SC;
color: #333333;
">进入我的——邮箱设置——服务器设置——完成，检查收件服务器是否连接成功，如断开，检查网络是否正常，若网络正常建议删除邮箱重新绑定，推荐使用163邮箱。</p>
<div class="wangyi">
    <img src="./img/image16.png" alt="" srcset="" />
</div>`,
    flag: false,
    url: "../../../qqStmp.html",
    classifyId: 1,
    id: 101
  },
  
  {
    title: "手机丢失，如何找回司空里的文件？",
    index: 4,
    text: `
  <p style="
  line-height: 34px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #333333;
  ">司空不存储用户文件，只存储密钥，如果手机丢失，意味着存储在设备本地的文件丢失，无法找回。建议养成定期备份的习惯，将司空手机端的文件加密备份您自己的电脑或网盘。</p>`,
    flag: false,
    url: "../../../qqStmp.html",
    classifyId: 1,
    id: 17
  },
  {
    title: "APP卸载后司空里的文件如何找回？",
    index: 5,
    text: `
  <p style="
  line-height: 34px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #333333;
  ">无法找回。请谨慎操作，APP卸载前务必做好加密版块文件的备份或将所有文件解密并导出。</p>`,
    flag: false,
    url: "../../../qqStmp.html",
    classifyId: 1,
    id: 18
  },
  {
    title: "更换手机如何将司空里的内容导出？",
    index: 6,
    text: `
  <p style="
  line-height: 34px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #333333;
  ">聊天和邮件版块的内容在邮箱服务商处，换手机登录司空，历史消息会同步，无须做保存处理。加密版块的内容存于手机本地，需提前导出，导出操作如下：
  下载司空PC端，登录司空PC端——加密——司空备份——点击去备份——备份文件至电脑——手机扫码——备份完成，司空PC端请至公司官网https://www.qmake.com.cn/下载。</p>
  <div class="wangyi">
      <img src="./img/image4.png" alt="" srcset="" />
</div>`,
    flag: false,
    url: "../../../qqStmp.html",
    classifyId: 1,
    id: 20
  },
  {
    title: "为什么在同一网络下PC端还是备份不成功？",
    index: 7,
    text: `
  <p style="
  line-height: 34px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #333333;
  ">Windows电脑在首次安装司空时，请勾选允许应用通过防火墙在这些网络上通信，否则备份功能将会受影响。如果首次安装时选择了取消，请按如下路径操作：控制面板\系统和安全\Windows Defender 防火墙\允许的应用，勾选司空。完成设置后，在同一网络环境下就可以正常使用备份功能了。</p>
  <div class="wangyi">
      <img src="./img/image5.png" alt="" srcset="" />
</div></p>
<div class="wangyi">
      <img src="./img/image23.png" alt="" srcset="" />
</div>`,
    flag: false,
    url: "../../../qqStmp.html",
    classifyId: 1,
    id: 21
  },
  {
    title: "为什么绑定QQ邮箱的已发送消息在司空客户端不显示？",
    index: 8,
    text: `<p style="
    line-height: 34px;

font-size: 18px;
font-family: PingFangSC-Semibold, PingFang SC;
color: #333333;
">QQ邮箱的SMTP发信后保存到服务器功能默认是关闭状态，需要在邮箱网页版登录后进行设置，请按如下步骤操作：设置—账户—POP3/IMAP/SMTP/Exchange/CardDAV/CalDAV服务—收取选项，勾选SMTP发信后保存到服务器。保存更改后司空客户端就可以正常显示已发送记录了。</p>
<div class="wangyi">
    <img src="./noqq/2.png" alt="" srcset="" />
</div>
<div class="wangyi">
    <img src="./noqq/1.png" alt="" srcset="" />
</div>`,
    flag: false,
    url: "../../../qqStmp.html",
    classifyId: 1,
    id: 11
  },
  {
    title: "消息同步是否会消耗密钥？",
    index: 9,
    text: `
  <p style="
  line-height: 34px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #333333;
  ">消息同步不会消耗密钥，只有未读消息或邮件被查阅后才会消耗相应密钥。</p>`,
    flag: false,
    url: "../../../qqStmp.html",
    classifyId: 1,
    id: 22
  },
  {
    title: "出现闪退怎么办？",
    index: 10,
    text: `<p style="
    line-height: 34px;

font-size: 18px;
font-family: PingFangSC-Semibold, PingFang SC;
color: #333333;
">若您司空版本过低，手机内存不足，司空软件损坏，或网络不稳定等原因可能导致司空登录异常。建议更新最新版本，清理手机上不需要的应用后再尝试打开司空。</p>`,
    flag: false,
    url: "../../../qqStmp.html",
    classifyId: 1,
    id: 10
  },
  {
    title: "云盘下载至司空的文件存放在哪里？",
    index: 11,
    text: `<p style="
    line-height: 34px;

font-size: 18px;
font-family: PingFangSC-Semibold, PingFang SC;
color: #333333;
">云盘下载的文件统一存放在加密——本地——下载分类下，下载分类里的文件目前可支持移动至文件分类下。</p>
<div class="wangyi" style="width:334px; margin:0">
    <img src="./img/image14.png" alt="" srcset="" />
</div>`,
    flag: false,
    url: "../../../qqStmp.html",
    classifyId: 1,
    id: 12
  },
  {
    title: "云盘里的文件是否可以进行重命名/删除/移动等操作？",
    index: 12,
    text: `<p style="
    line-height: 34px;

font-size: 18px;
font-family: PingFangSC-Semibold, PingFang SC;
color: #333333;
">司空客户端暂不支持云盘下文件的管理，只能进行选中和下载操作，其他文件管理功能需前往百度网盘客户端进行操作。</p>`,
    flag: false,
    url: "../../../qqStmp.html",
    classifyId: 1,
    id: 13
  },
  {
    title: "司空如何解绑网盘？",
    index: 13,
    text: `<p style="
    line-height: 34px;

font-size: 18px;
font-family: PingFangSC-Semibold, PingFang SC;
color: #333333;
">进入加密——云盘——点击右上角解绑按钮——查看解绑说明
<br>
方式一：进入百度网盘网页版——个人主页——授权管理——选择司空——解除授权
<br>
方式二：进入百度网盘app——设置——账号管理——授权管理——选择司空——解除授权</p>
<div class="wangyi">
    <img src="./img/image15.png" alt="" srcset="" />
</div>`,
    flag: false,
    url: "../../../qqStmp.html",
    classifyId: 1,
    id: 14
  },
  {
    title: "如何将聊天、邮件里的文件另存为？",
    index: 14,
    text: `<p style="
    line-height: 34px;

font-size: 18px;
font-family: PingFangSC-Semibold, PingFang SC;
color: #333333;
">聊天、邮件里的文件安卓端暂不支持另存为，如需保存请扫码登录PC端进行操作。司空PC端请至公司官网https://www.qmake.com.cn/下载。</p>`,
    flag: false,
    url: "../../../qqStmp.html",
    classifyId: 1,
    id: 15
  },
  {
    title: "如何删除聊天/邮件内容？",
    index: 15,
    text: `
  <p style="
  line-height: 34px;

  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #333333;
  ">通过司空删除，或者登录邮箱客户端进行删除。</p>`,
    flag: false,
    url: "../../../qqStmp.html",
    classifyId: 1,
    id: 16
  },
  
//   {
//     title: "更换手机如何将司空里的内容导出？",
//     text: `
//   <p style="
//   line-height: 34px;
//   font-size: 18px;
//   font-family: PingFangSC-Semibold, PingFang SC;
//   color: #333333;
//   ">聊天和邮件版块的内容在邮箱服务商处，换手机登录司空，历史消息会同步，无须做保存处理。加密版块的内容存于手机本地，需提前导出，导出操作如下：
// 下载司空PC端，登录司空PC端——加密——司空备份——点击去备份——备份文件至电脑——手机扫码——备份完成，司空PC端请至公司官网https://www.qmake.com.cn/下载。</p>
//   <div class="wangyi">
//       <img src="./img/image4.png" alt="" srcset="" />
// </div>`,
//     flag: false,
//     url: "../../../qqStmp.html",
//     classifyId: 1,
//     id: 19
//   },

 
 
  {
    title: "如何发送加密邮件？",
    index: 1,
    text: `
  <p style="
  line-height: 34px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #333333;
  ">进入邮件——写邮件——选择你的司空好友——量子密钥加密开关默认打开——输入主题及邮件内容——点击发送。
  <div class="wangyi">
      <img src="./img/image6.png" alt="" srcset="" />
</div></p>`,
    flag: false,
    url: "../../../qqStmp.html",
    classifyId: 5,
    id: 501
  },
  {
    title: "如何安全聊天？",
    index: 2,
    text: `
  <p style="
  line-height: 34px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #333333;
  ">进入聊天——从列表里选择好友——进入聊天页面——量子密钥加密开关默认打开——编辑内容——点击发送。
首次给好友发送消息可进入好友——选择好友——点击发信息——进入聊天页面——量子密钥加密开关默认打开——编辑内容——点击发送。
  <div class="wangyi">
      <img src="./img/image7.png" alt="" srcset="" />
</div></p>`,
    flag: false,
    url: "../../../qqStmp.html",
    classifyId: 5,
    id: 502
  },
  {
    title: "如何发送无痕阅览消息/无痕阅览邮件？",
    index: 3,
    text: `
    <p style="
  line-height: 34px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #333333;
  ">进入聊天详情页——打开右上角无痕阅览开关——编辑发送消息内容；进入写邮件——打开无痕阅览开关——编辑发送邮件内容。
收到无痕阅览的消息或邮件，查阅后就会自动删除。
  <div class="wangyi">
      <img src="./img/image8.png" alt="" srcset="" />
</div></p>`,
    flag: false,
    url: "../../../qqStmp.html",
    classifyId: 5,
    id: 503
  },
  {
    title: "如何注册/登录？",
    index: 1,
    text: `
    <p style="
  line-height: 34px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #333333;
  ">第1步：输入手机号及验证码。请您使用真实手机号在本人手机上完成注册，确保加解密功能使用正常；
第2步：设置APP启动密码，输入6位数字或手势密码，登录并完成注册。`,
    flag: false,
    url: "../../../qqStmp.html",
    classifyId: 2,
    id: 201
  },
  {
    title: "如何绑定邮箱？",
    index: 2,
    text: `
    <p style="
  line-height: 34px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #333333;
  ">进入我的——邮箱设置——点击“+”按钮进行添加——选择邮箱服务商——输入邮箱账号及授权码——点击登录——绑定成功。</p>`,
    flag: false,
    url: "../../../qqStmp.html",
    classifyId: 2,
    id: 202
  },
  {
    title: "如何修改密码？",
    index: 3,
    text: `
    <p style="
  line-height: 34px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #333333;
  ">进入我的——密码设置——输入验证码——输入新密码——设置完成</p>`,
    flag: false,
    url: "../../../qqStmp.html",
    classifyId: 2,
    id: 203
  },
  {
    title: "忘记密码，怎么办？",
    index: 4,
    text: `
    <p style="
  line-height: 34px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #333333;
  ">点击忘记密码——输入验证码——输入新密码——设置完成</p>`,
    flag: false,
    url: "../../../qqStmp.html",
    classifyId: 2,
    id: 204
  },
  {
    title: "文件导入后删除源文件如何操作？",
    index: 5,
    text: `
    <p style="
  line-height: 34px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #333333;
  ">文件导入司空，源文件依旧在本地设备的原路径下，文件加密后为了保证安全，可以将源文件删除，该功能需进行设置，进入我的——导入后删除源文件——打开开关——设置完成。</p>`,
    flag: false,
    url: "../../../qqStmp.html",
    classifyId: 2,
    id: 205
  },
  {
    title: "如何注销账户？",
    index: 6,
    text: `
    <p style="
  line-height: 34px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #333333;
  ">进入我的——注销账户——输入手机号验证码——认真阅读注销说明——确认注销。</p>`,
    flag: false,
    url: "../../../qqStmp.html",
    classifyId: 2,
    id: 206
  },
  {
    title: '如何进行文件加解密？',
    index: 1,
    text: `
    <p style="
  line-height: 34px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #333333;
  ">第1步：导入，进入加密——本地——点击“+”照片——点击导入，进入本地相册（此处可选择是否开启“导入后删除源文件”开关）——选择照片——导入成功；
  <br>
第2步：加密，进入加密——本地——照片分类下找到导入的照片——直接点击锁头加密或长按选中文件，通过右下角更多——加密。解密同理操作。
<br>
若导入视频，点击“+”视频，进入本地视频文件夹；若导入文件，点击“+”文件，进入设备文件浏览页面。照片导入位置默认照片分类下，视频导入位置默认视频分类下，照片/视频也可以选择导入文件分类下，但文件只能导入文件分类下。。
  <div class="wangyi">
      <img src="./img/image9.png" alt="" srcset="" />
</div>
</p>
<p>
<div class="wangyi">
      <img src="./img/image24.png" alt="" srcset="" />
</div></p>`,
    flag: false,
    url: "../../../qqStmp.html",
    classifyId: 4,
    id: 401
  },
  {
    title: "如何向好友加密分享文件？",
    index: 2,
    text: `
    <p style="
  line-height: 34px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #333333;
  ">第1步：授权，进入加密——本地——照片/视频/文件——长按选中文件——点击左下角授权按钮——选择好友——分配权限；
  <br>
  第2步：分享，长按选中文件——点击下方分享按钮——继续分享——选择分享方式（安卓端目前仅支持微信、QQ分享）——完成分享。也可以先分享后授权。
  <div class="wangyi">
      <img src="./img/image17.png" alt="" srcset="" />
</div>
<div class="wangyi" style="margin-top:24px">
      <img src="./img/image18.png" alt="" srcset="" />
</div></p>
`,
    flag: false,
    url: "../../../qqStmp.html",
    classifyId: 4,
    id: 402
  },
  {
    title: "如何打开好友分享的加密文件？",
    index: 3,
    text: `
    <p style="
  line-height: 34px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #333333;
  ">点击收到的加密文件——用其他应用打开——选择司空——选择要保存至的分类路径（照片/视频/文件）——进入相应分类下——解密阅览。
  <div class="wangyi">
      <img src="./img/image19.png" alt="" srcset="" />
</div>
<div class="wangyi" style="margin-top:24px">
      <img src="./img/image20.png" alt="" srcset="" />
</div></p>
`,
    flag: false,
    url: "../../../qqStmp.html",
    classifyId: 4,
    id: 403
  },
  {
    title: "加密文件如何上传网盘？",
    index: 4,
    text: `
    <p style="
  line-height: 34px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #333333;
  ">第1步：进入加密——云盘——输入网盘账号（目前仅支持百度网盘）——密码或短信验证码登录——绑定成功；
  <br>
  第2步：进入加密——本地——照片/视频/文件——长按选中要上传的文件——点击下方上传云盘——选择网盘的存放路径——确认后上传成功。
  <br>
  上传成功后，可以在加密——云盘——对应文件夹下找到上传的文件。
  <div class="wangyi">
      <img src="./img/image21.png" alt="" srcset="" />
</div>
<div class="wangyi" style="margin-top:24px">
      <img src="./img/image22.png" alt="" srcset="" />
</div><</p>
`,
    flag: false,
    url: "../../../qqStmp.html",
    classifyId: 4,
    id: 404
  },
  {
    title: "网盘上的加密文件如何解密阅览？",
    index: 5,
    text: `
    <p style="
  line-height: 34px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #333333;
  ">第1步：进入加密——云盘——长按选中文件——点击下载按钮；
  <br>
第2步：进入加密——本地——下载，在下载分类下找到已下载的文件进行解密操作。
  <div class="wangyi">
      <img src="./img/image13.png" alt="" srcset="" />
</div></p>
`,
    flag: false,
    url: "../../../qqStmp.html",
    classifyId: 4,
    id: 405
  },
  {
    title: "如何将手机端司空的文件备份至电脑端？",
    index: 6,
    text: `
    <p style="
  line-height: 34px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #333333;
  ">下载司空PC端，登录司空PC端——进入加密——司空备份——点击去备份——备份文件至电脑——手机扫码——备份完成，司空PC端请至公司官网https://www.qmake.com.cn/下载。
  <div class="wangyi">
      <img src="./img/image12.png" alt="" srcset="" />
</div></p>
`,
    flag: false,
    url: "../../../qqStmp.html",
    classifyId: 4,
    id: 406
  },
  {
    title: "如何将电脑端备份的司空文件导出至手机？",
    index: 7,
    text: `
    <p style="
  line-height: 34px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #333333;
  ">登录司空PC端——进入加密——司空备份——点击去备份——导出文件至手机——选择需要导出的文件夹并确定——手机扫码——导出完成。
  <div class="wangyi">
      <img src="./img/image10.png" alt="" srcset="" />
</div></p>
<div class="wangyi">
      <img src="./img/image11.png" alt="" srcset="" />
</div>
`,
    flag: false,
    url: "../../../qqStmp.html",
    classifyId: 4,
    id: 407
  },
  {
    title: "如何添加好友？",
    index: 1,
    text: `
    <p style="
  line-height: 34px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #333333;
  ">方式一：通过对方二维码名片扫码添加；
  <br>
  方式二：通过输入对方手机号添加（非司空用户，无法添加）。</p>`,
    flag: false,
    url: "../../../qqStmp.html",
    classifyId: 3,
    id: 301
  },
  
  
  
  
  
 
    ]