<template>
  <div class="homePage">
    <!-- <div @click="tabshandleClick">1111111</div> -->
    <!-- <headerPage></headerPage> -->
    <div class="banner">
      <!-- <img src="../../assets/banner.png" alt="" /> -->
    </div>
    <div class="characteristics">
      <h2
        style="
          margin: 60px 0 40px 0;
          text-align: left;
          line-height: 1;
          color: #333333;
        "
      >
        会员用户权益：<span style="color: #4f77e1"
          >1、密钥使用量不限 2、密钥永久保存</span
        >
      </h2>
      <div class="characteristicsList" ref="content">
        <div
          class="item"
          v-for="(item, index) in characteristicsList"
          :key="index"
          style="border-radius: 12px"
        >
          <h2>{{ item.vTypename }}</h2>
          <p>¥{{ item.vPrice }}</p>
          <div class="payBox" @click="pay(item)">立即开通</div>
        </div>
      </div>
    </div>
    <el-dialog
      title=""
      :show-close="true"
      custom-class="dialogBox"
      :visible.sync="dialogVisible"
      width="360px"
      :before-close="handleClose"
    >
      <div class="lable">请输入手机号</div>
      <div>
        <el-input
          :rows="2"
          placeholder=""
          style="width: 280px"
          v-model="form.phone"
          @input="inPhoneNum"
        >
        </el-input>
      </div>
      <div class="lable">输入验证码</div>
      <div>
        <el-input
          :rows="2"
          placeholder=""
          style="width: 200px"
          v-model="form.verificationCode"
        >
        </el-input>
        <span
          v-show="show"
          style="margin-left: 10px; color: #4f77e1; font-size: 14px;cursor: pointer;"
          @click="getVerificationCode"
          >获取验证码</span
        >
        <span
          style="margin-left: 10px; color: #4f77e1; font-size: 14px;cursor: pointer;"
          @click="getVerificationCode"
          v-show="!show"
          class="count"
          >{{ count }}s后获取</span
        >
        <div class="tips" v-if="!isMember">
          您还不是司空用户<br/> 请在应用市场下载APP后进行注册
        </div>
        <div class="btn" @click="submitForm" v-if="isSubmit">
          <img src="../../assets/yesLogin.png" alt="" />
        </div>
        <div class="btn" v-else>
          <img src="../../assets/noLogin.png" alt="" />
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title=""
      :show-close="true"
      custom-class="dialogBoxs"
      :visible.sync="dialogVisibles"
      width="320px"
      :before-close="handleClose"
    >
      <div>
        <canvas
          v-if="payType == 1"
          id="canvas"
          style="width: 200px; height: 200px; display: flex; margin: auto"
        ></canvas>
        <!-- <div
        v-if="payType == 2" style="width: 200px; height: 200px; display: flex; margin: auto" class="">

        </div> -->
        <img
          v-if="payType == 2"
          src="https://gw.alipayobjects.com/mdn/rms_9e4c39/afts/img/A*YjvJQLBrZbYAAAAAAAAAAAAAARQnAQ"
          style="width: 200px; height: 200px; display: flex; margin: auto"
          alt=""
          srcset=""
        />
        <div class="payType">
          <div style="margin: auto">
            <div
              class="payBtn"
              @click="payBtn(1)"
              :class="payType == 1 ? 'ispayBtn' : ''"
            >
              <img
                v-if="payType == 1"
                src="../../assets//isweixin.png"
                alt=""
              />
              <img
                v-if="payType == 2"
                src="../../assets//weixin.png"
                alt=""
              />微信支付
            </div>
            <div
              class="payBtn"
              @click="payBtn(2)"
              :class="payType == 2 ? 'ispayBtn' : ''"
            >
              <img src="../../assets//zhifubao.png" alt="" />支付宝支付
            </div>
          </div>
          <!-- <img src="../../assets/images/weixin.png" alt="" />
        <img src="../../assets/images/zhifubao.png" alt="" />
        扫码支付更轻松 -->
        </div>
      </div>
    </el-dialog>
  </div>
</template>
  
  <script>
import payModel from "@/components/payModel.vue";
import QRCode from "qrcode";
import {
  getVipCodeInfo,
  getWebsiteRequireCode,
  getZFBPaymentQRCode,
  getWXPaymentQRCode,
  getwxQueryOrder,
  getzfbQueryOrder,
} from "@/config/api";
export default {
  data() {
    return {
      characteristicsList: [],
      dialogVisible: false,
      dialogVisibles: false,
      form: {
        verificationCode: "",
        phone: "",
      },
      fileList: [],
      formData: new FormData(),
      imageFile: null,
      isSubmit: false,
      payType: 1,
      verificationCode: "",
      payData: {},
      timer: null,
      show: true,
      count: "",
      wxQrcode: "",
      zfbQrcode: "",
      isMember: true,
      getwxTimer: null,
      getzfbTimer: null,
      wxInfoData: {},
    };
  },
  components: {
    payModel,
  },
  watch:{
    dialogVisibles(val){
      if(!val){
        clearInterval(this.getwxTimer)
        clearInterval(this.getzfbTimer)
      }
    }
  },
  mounted() {
    document.documentElement.scrollTop = 0;

    this.$refs.content.onscroll = () => {
      this.handleScroll();
    };
    this.getVipCodeInfo();
  },
  methods: {
    getVipCodeInfo() {
      getVipCodeInfo().then((res) => {
        console.log(res);
        this.characteristicsList = res.data.vipCodeAndInfoList.slice(4);
      });
    },
    getVerificationCode() {
      if (this.form.phone) {
        const TIME_COUNT = 60;
        getWebsiteRequireCode({
          phone: this.form.phone,
        }).then((res) => {
          console.log(res);
          if (res.code == "00") {
            this.verificationCode = res.data.requireCode;
            if (!this.timer) {
              this.count = TIME_COUNT;
              this.show = false;
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                  this.count--;
                } else {
                  this.show = true;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
          } else if (res.code == "32") {
            this.isMember = false;
            // this.$message.error("您还不是司空用户 请在应用市场下载APP");
          } else if (res.code == "59") {
            this.$message.error(res.errMsg);
          }
        });
      } else {
        this.$message.error("请输入手机号");
      }
    },
    submitForm() {
      if (this.form.verificationCode == "") {
        this.$message.error("请输入验证码");
      } else {
        if (this.verificationCode == this.form.verificationCode) {
          this.dialogVisible = false;
          this.dialogVisibles = true;
          sessionStorage.setItem("loginFlag", true);
          sessionStorage.setItem("phone", this.form.phone);
          clearInterval(this.timer);
          console.log(this.form.phone);
          this.weChatPay();
        } else {
          this.$message.error("验证码不正确，请重新获取验证码");
        }
      }
    },
    pay(data) {
      this.payData = data;
      let loginFlag = sessionStorage.getItem("loginFlag");
      console.log(loginFlag == "true", "loginFlag");
      if (loginFlag == "true") {
        this.dialogVisibles = true;
        this.weChatPay();
      } else {
        this.dialogVisible = true;
      }
      this.getwxQueryOrder();
    },
    payBtn(num) {
      this.payType = num;
      if (num == 2) {
        this.aliPay();
      } else {
        this.weChatPay();
      }
    },
    aliPay() {
      console.log(this.payData);
      let phone = sessionStorage.getItem("phone");
      console.log(phone);
      getZFBPaymentQRCode({ phone: phone, vipCode: this.payData.vCode }).then(
        (res) => {
          console.log(res.data.ZFB_QR_CODE_MESSAGE, "res.data");
          this.zfbQrcode = res.data.ZFB_QR_CODE_MESSAGE;
          this.getzfbQueryOrder();

          // window.open(this.zfbQrcode)
          sessionStorage.setItem("alipayInfo", this.zfbQrcode);
          const { href } = this.$router.resolve({
            path: "/payPage",
            query: {},
          });
          window.open(href, "_blank");
        }
      );
    },
    weChatPay() {
      console.log(this.payData);
      let phone = sessionStorage.getItem("phone");
      console.log(phone);
      getWXPaymentQRCode({ phone: phone, vipCode: this.payData.vCode }).then(
        (res) => {
          this.wxQrcode = res.data.WX_QR_CODE_MESSAGE.messageData.code_url;
          this.wxInfoData = res.data.WX_QR_CODE_MESSAGE.messageData;
          let canvas = document.getElementById("canvas");
          QRCode.toCanvas(
            canvas,
            this.wxQrcode,
            { width: 200 },
            function (error) {
              if (error) console.error(error);
              console.log("success!");
            }
          );
          console.log(this.$refs.content);
        }
      );
    },
    inPhoneNum(e) {
      console.log(e);
      if (e) {
        if (e.indexOf("@") == -1) {
          var phone = e.replace(/\s/g, ""); //去除空格
          let regs = /^((1[0-9]))\d{9}$/;

          if (!regs.test(phone)) {
            // this.$message({
            //   message: "手机号不正确",
            //   type: "warning",
            // });
            this.isSubmit = false;
            return false;
          } else {
            // delete this.form.type;
            this.isSubmit = true;
            return true;
            // if (!this.fileList.length) {
            //   this.formData.set("file", this.imageFile);
            // }
            // 带图片
          }
        } else {
          var mail = e.replace(/\s/g, ""); //去除空格
          var strRegex = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
          if (!strRegex.test(mail)) {
            this.isSubmit = false;
            return false;
          } else {
            this.isSubmit = true;
            return true;
          }
        }
      } else {
        this.isSubmit = false;
        return false;
      }
    },
    handleClose() {
      this.dialogVisible = false;
      this.dialogVisibles = false;
    },
    getwxQueryOrder() {
      this.getwxTimer = setInterval(() => {
        let phone = sessionStorage.getItem("phone");
        getwxQueryOrder({
          phone: phone,
          outTradeNo: this.wxInfoData.out_trade_no,
        }).then((res) => {
          if (res.data.trade_state == "SUCCESS") {
            this.$message.success("支付成功");
            this.dialogVisibles = false;
            clearInterval(this.getwxTimer);
            clearInterval(this.getzfbTimer);
          }
        });
      }, 10000);
    },
    getzfbQueryOrder() {
      this.getzfbTimer = setInterval(() => {
          let phone = sessionStorage.getItem("phone");
          let strstate = this.zfbQrcode.indexOf("{&quot;out_trade_no&quot;:&quot;");
          let strend = this.zfbQrcode.indexOf("&quot;,&quot;total_amount");
          let str = this.zfbQrcode
            .slice(strstate, strend)
            .replace("{&quot;out_trade_no&quot;:&quot;", "");
         getzfbQueryOrder({
          phone: phone,
          outTradeNo: str,
        }).then((res) => {
          if (res.data.queryOrderState == "TRADE_SUCCESS") {
            this.$message.success("支付成功");
            this.dialogVisibles = false;
            clearInterval(this.getzfbTimer);
            clearInterval(this.getwxTimer);
          }
        });
      }, 10000);
    },
  },
  destroyed() {
    clearInterval(this.getwxTimer);
    clearInterval(this.getzfbTimer);
  },
};
</script>
  
  <style lang="scss" scoped>
// @media screen and (max-width: 992px) {
//   .homePage {
//     width: 177vh;
//     margin: 0 auto;
//   }
// }
// @media only screen and (min-width: 1401px) {
.banner {
  // width: auto;
  width: auto;
  height: 500px;
  margin: 0 auto;
  background: url(../../assets/payMember.png) no-repeat center center;
  background-size: cover;
}
// }
// @media only screen and (max-width: 1400px) {
// .banner {
//   // width: auto;
//   width: auto;
//   height: 522px;
//   margin: 0 auto;
//   background: url(../../assets/banner.png) no-repeat center center;
//   background-size: 100%;
// }
// }
// @media only screen and (max-width: 640px) {
//   .banner {
//     height: 800px;
//   }
// }
.characteristics {
  width: 1200px;
  margin: auto;
  text-align: center;
  .characteristicsList {
    display: flex;
    margin-bottom: 66px;
    // justify-content: space-between;
    .item {
      width: 280px;
      height: 226px;
      //   padding: 60px 30px 0;
      margin-right: 170px;
      box-shadow: 0px 0px 10px 0px rgba(165, 189, 251, 0.2);
      h2 {
        letter-spacing: 2px;
        line-height: 1;
        margin: 58px 0 20px;
      }
      img {
        width: 80px;
        height: 80px;
      }
      p {
        font-size: 32px;
        margin: 0 0 58px;
        font-weight: 600;
        color: #4f77e1;
        line-height: 45px;
      }
      .payBox {
        width: 280px;
        height: 64px;
        background: #4f77e1;
        border-radius: 0 0 12px 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        color: #ffffff;
        line-height: 22px;
        cursor: pointer;
      }
    }
    .item:hover {
      background: #ffffff;
      box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.08);
      border-radius: 12px;
    }
  }
}
.lable {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
  margin: 20px 0 10px;
}
.feekType {
  padding: 6px;
  border: solid 1px #dfdfdf;
  border-radius: 6px;
  margin-right: 20px;
}
.feekTypeSelect {
  // background: #f8f8f8;
  background-color: rgba(79, 119, 225, 1);
  color: white;
  border: none;
}
/deep/.el-textarea__inner {
  background: #f8f8f8 !important;
}
/deep/.el-input__inner {
  background: #f8f8f8 !important;
}
.btn {
  width: 240px;
  height: 36px;
  margin: 30px auto 10px;
  margin-left: 20px;
  img {
    width: 100%;
    height: 100%;
  }
}
/deep/.dialogBox .el-dialog__body {
  padding-top: 0 !important;
  margin-left: 20px;
}
/deep/.dialogBoxs .el-dialog__body{
  padding-left: 0;
  padding-right: 0;
}
/deep/.el-upload--picture-card {
  width: 56px;
  height: 56px;
  line-height: 62px;
}
/deep/.el-upload-list--picture-card .el-upload-list__item {
  width: 56px;
  height: 56px;
}
.functionPage img {
  width: 100%;
  height: 100%;
}
.tips {
  width: 210px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ff0000;
  line-height: 20px;
  text-align: center;
  margin: 10px auto;
  margin-top: 30px;
  margin-left: 34px;
}
.payType {
  display: flex;
  margin-top: 20px;
  align-items: center;
  .ispayBtn {
    background: #4f77e1;
    color: #ffffff !important;
  }
  .payBtn {
    width: 118px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid #4f77e1;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4f77e1;
    line-height: 22px;
    display: flex;
    align-items: center;
    margin: 20px 0;
  }
  img {
    width: 18px;
    height: 18px;
    margin: 0 10px;
  }
}
/deep/.dialogBox {
  border-radius: 12px !important;
}
</style>