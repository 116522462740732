<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <headerPage></headerPage>
    <router-view></router-view>
    <functionBox></functionBox>
    <footerPage></footerPage>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import headerPage from "@/components/header.vue";
import footerPage from "@/components/footer.vue";
import functionBox from "@/components/functionBox.vue";
export default {
  name: "App",
  components: {
    headerPage,
    footerPage,
    functionBox
  },
  mounted(){
    console.log('3.3.9版本')
  }
};
</script>

<style>
#app{
      width: auto;
}
body,html {
    margin: 0;
    padding: 0;
}
h1,h2{
  color: #333333;
}
p{
  color: #666666;
}
em,i {
    font-style: normal;
}
li {
    list-style: none;
}
img {
    border: 0;
    vertical-align: middle;
}
button {
    cursor: pointer;
}
 
/* button,
.hide,.none {
    display: none;
} */
/* 伪元素清除浮动 */
.clearfix:after {
    visibility: hidden;
    clear: both;
    display: block;
    content: ".";
    height: 0;
}
.clearfix {
    *zoom: 1
}
/* #app {
    
 } */
</style>
