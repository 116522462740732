import axios from 'axios'
import  baseURL  from '../config/index.js'

// 创建实例
// console.log(baseURL)
const instance = axios.create({
    baseURL: baseURL.baseURL,
    timeout: 10000,
    headers: {
        "Content-Type": "application/json", //默认请求头为它
        // "Content-Type": "text/html",
        // 'Content-Type': 'application/x-www-form-urlencoded',

    },
})
// 添加请求拦截器
instance.interceptors.request.use(function (config) {
    return config;
}, function (error) {
    return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    if (response.status === 200) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
}, function (error) {
    return Promise.reject('未知错误-->' + error);
});
//将实例抛出去
export default instance;
