<template>
  <div>
    <div class="headerPage">
    <div class="logo">
      <img src="../assets/logo.png" alt="" srcset="" />
    </div>
    <div class="headerNav">
      <div class="nav" :class="selectIndex==index?'navSelect':''" v-for="(item, index) in headerNav" :key="index" @click="goRouter(item,index)">
        {{ item.name }}
      </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headerNav: [
        {
          name: "首页",
          path: '/'
        },
        {
          name: "司空",
          path: '/sikong'
        },
        {
          name: "下载",
          path: '/downloadpage'
        },
        // {
        //   name: "购买会员",
        //   path: '/payMember'
        // },
        {
          name: "帮助",
          path: '/help'
        },
        {
          name: "关于我们",
          path: '/about'
        },
      ],
      selectIndex: 0
    };
  },
  mounted(){
    // console.log(this.$route.path)
    this.headerNav.map((item,index)=>{
        if(item.path == this.$route.path){
          this.selectIndex = index 
        }
      })
  },
  watch: {
    '$route.path'(val){
      // console.log(val)
      this.headerNav.map((item,index)=>{
        if(item.path == val){
          this.selectIndex = index 
        }
      })
    }
  },
  computed: {

  },
  methods:{
      goRouter(item){
          // console.log(item)
          this.$router.push(item.path)
      }
  }
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 640px) {
  .headerPage {
    width: 100%;
  }
}
.headerPage {
  width: 1180px;
  height: 80px;
  // background: red;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
  }
  .logo {
    width: 84px;
    height: 64px;
  }
  .headerNav {
    display: flex;
    .nav {
      font-size: 18px;
      font-weight: 500;
      color: #333333;
      margin-left: 55px;
    }
    .navSelect{
      color: #4F77E1;
    }
    .nav:hover{
        cursor:pointer
    }
  }
}
</style>