<template>
    <div></div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        payGateWay: "",
      };
    },
    mounted() {
      this.payGateWay = sessionStorage.getItem("alipayInfo");
      console.log(this.payGateWay)
      // let form = this.$route.query.htmlData;
      // this.payGateWay = form;
      console.log(this.payGateWay);
      const div = document.createElement("div"); // 创建div
      div.innerHTML = this.payGateWay; // 将返回的form 放入div
      document.body.appendChild(div);
      document.forms[0].submit();
    },
  };
  </script>
  
  <style>
  </style>