<template>
  <div ref="content">
    <!-- <headerPage></headerPage> -->
    <div class="banner">
      <!-- <img src="../../assets/banner2.png" alt="" /> -->
    </div>
    <div class="characteristics">
      <div>
        <h1 style="
            margin: 60px 0 22px;
            line-height: 1;
            color: #333333;
            letter-spacing: 5px;
          ">
          司空
        </h1>
        <p>
          司空作为一款安全处理个人事务平台，为用户提供一站式的数据强加密服务。基于量子真随机密钥，通过数据与密钥分离的有效机制，帮助用户排除在通信和数据存储、分享过程中产生的不安心、不踏实的困扰，真正保护用户的数据和隐私安全。
        </p>
      </div>
      <div class="dataInfo">
        <div class="leftiamge">
          <img src="../../assets/image1.png" alt="" />
        </div>
        <div class="rightInfo">
          <div class="header">
            <img src="../../assets/file.png" alt="" />文件加密
          </div>
          <div class="Info">
            加密版块是一套文件加密系统。用户使用司空可以对照片、视频、文件等进行加解密，只需一键点击，即可轻松完成。加密后的文件，既可以存储在本地设备上，也可以存储在用户自己的U盘或者网盘上，还可以通过微信、QQ等社交软件分享给自己的好友。
          </div>
        </div>
      </div>
      <div class="dataInfo">
        <div class="rightInfo">
          <div class="header">
            <img src="../../assets/chat.png" alt="" />邮件加密
          </div>
          <div class="Info">
            邮件版块是一套基于电子邮箱的加密系统，它能够对用户邮件内容进行加密，确保用户邮件在网络传输、中转和存储全程处于强加密状态。基于电子邮箱的聊天模式，能够更好地满足用户的使用习惯。
          </div>
        </div>
        <div class="leftiamge" style="margin-left: 183px">
          <img src="../../assets/image2.png" alt="" />
        </div>
      </div>

      <div class="TabControl">
        <img :src="oneList[tabIndex].src" alt="">
        <div
          :style="tabIndex == index ? 'background-image: url(../../img/tabbg.png); background-repeat: no-repeat;background-size: 100% 100%; color: white; box-shadow: 0px 0px 8px 6px rgba(79,119,225,0.08);' : ''"
          v-for="(item, index) in oneList" class="tabBtn" @mouseenter="handleMouseEnter(item, index)">
          <p :style="tabIndex == index ? 'color: #FFFFFF' : ''" class="tabTitle">{{ item.title }}</p>
          <p :style="tabIndex == index ? 'color: #FFFFFF' : ''" class="tabText">{{ item.text }}</p>
        </div>
      </div>

      <div class="TabControl">
        <div style="margin: 0 0 0 40px;"
          :style="tabIndex2 == index ? 'background-image: url(../../img/tabbg.png); background-repeat: no-repeat;background-size: 100% 100%; color: white;box-shadow: 0px 0px 8px 6px rgba(79,119,225,0.08); ' : ''"
          v-for="(item, index) in twoList" class="tabBtn" @mouseenter="twohandleMouseEnter(item, index)">
          <p :style="tabIndex2 == index ? 'color: #FFFFFF' : ''" class="tabTitle">{{ item.title }}</p>
          <p :style="tabIndex2 == index ? 'color: #FFFFFF' : ''" class="tabText">{{ item.text }}</p>
        </div>
        <img style="margin: 0 0 0 76px;" :src="twoList[tabIndex2].src" alt="">
      </div>

      <div class="bgImage">
        <div class="bgInfo">
          <p class="bgImageTitle">支持任意格式文件加解密</p>
          <p class="bgImageText">支持office文件、压缩文件及图片、视频等任意格式的文件一键加解密。</p>
        </div>
      </div>
      <div class="bgImage2">
        <div class="bgInfo">
          <p class="bgImageTitle">多端同步，无缝切换</p>
          <p class="bgImageText">PC端、移动端无缝漫游，办公生活两不误。</p>
        </div>
      </div>
    </div>
  </div>
</template> 

<script>
// import headerPage from '@/components/header.vue'
import swiper from "@/components/swiper.vue";
import { set } from "vue";

export default {
  components: {
    swiper,
  },
  data() {
    return {
      tabIndex: 0,
      oneList: [{
        title: '操作便捷',
        text: '用于加解密的量子密钥由系统自动分配，只需一键点击即可轻松完成。',
        src: require('../../assets/oneImage1.png')
      },
      {
        title: '双重加密',
        text: '同时对抗设备短时失控和非法技术暴力破解。',
        src: require('../../assets/oneImage2.png')
      },
      {
        title: '权限控制',
        text: '向好友分配密钥权限，信息点对点精准触达，无惧文件错发、丢失。',
        src: require('../../assets/oneImage3.png')
      }],
      tabIndex2: 0,
      twoList: [{
        title: '好友加密分享',
        text: '加密文件可以通过第三方软件（微信、QQ等）分享给好友。',
        src: require('../../assets/twoImage1.png')
      },
      {
        title: '网盘加密备份',
        text: '加密文件一键上传，安享云端备份。',
        src: require('../../assets/twoImage2.png')
      },
      {
        title: '安全聊天',
        text: '加密邮件的聊天模式使商务交流高效顺畅，商务机密尽在掌握中。',
        src: require('../../assets/twoImage3.png')
      },]
    };
  },
  mounted() {
    document.documentElement.scrollTop = 0;
    if (this.$store.state.anchorInfo.flag) {
      this.tabshandleClick(this.$store.state.anchorInfo);
    }
  },
  computed: {
    anchorInfo() {
      return this.$store.state.anchorInfo;
    },
  },
  watch: {
    anchorInfo(val) {
      this.tabshandleClick(val);
      // console.log(123);
    },
  },

  methods: {
    handleScroll() {
      let scrollTop = this.$refs.content.scrollTop;
      let blocks = document.querySelectorAll(".dataInfo");
      blocks.forEach((item, index) => {
        // console.log("blocks", item.id);
        if (scrollTop >= item.offsetTop) {
          this.activeTabs = item.id;
        }
      });
    },
    tabshandleClick(data) {
      // console.log("data", data);
      let index = Number(data.index);
      let blocks = document.querySelectorAll(".dataInfo");
      let step = 20; //滚动步长
      let currentScrollTop = this.$refs.content.scrollTop;
      // console.log("blocks", blocks);
      let targetOffsetTop = blocks[index].offsetTop;
      // console.log("currentScrollTop", currentScrollTop);
      // console.log("targetOffsetTop", targetOffsetTop);
      if (currentScrollTop > targetOffsetTop) {
        const smoothUp = () => {
          if (currentScrollTop >= targetOffsetTop) {
            if (currentScrollTop - targetOffsetTop >= step) {
              currentScrollTop -= step;
              setTimeout(smoothUp, 1);
            } else {
              currentScrollTop = targetOffsetTop - step;
            }
            document.documentElement.scrollTop = currentScrollTop;
          }
        };
        smoothUp();
      } else {
        const smoothDown = () => {
          if (currentScrollTop <= targetOffsetTop) {
            // 如果和目标相差距离大于等于step 就跳 step
            if (targetOffsetTop - currentScrollTop >= step) {
              currentScrollTop += step;
              setTimeout(smoothDown, 1);
            } else {
              // 否则直接跳到目标点，防止跳过
              currentScrollTop = targetOffsetTop - step;
            }
            document.documentElement.scrollTop = currentScrollTop;
            // console.log(this.$refs.content.scrollTop);
          }
        };
        smoothDown();
      }
      this.$store.commit("setFlag", false);
    },
    handleMouseEnter(data, index) {
      console.log(data)
      this.tabIndex = index
    },
    twohandleMouseEnter(data, index) {
      this.tabIndex2 = index
    },
  },
};
</script>

<style lang="scss" scoped>
// @media only screen and (min-width: 1401px) {
.banner {
  // width: auto;
  width: auto;
  height: 800px;
  margin: 0 auto;
  background: url(../../assets/banner2.png) no-repeat center center;
  background-size: cover;
  margin-left: -150px;
}

// }
// @media only screen and (max-width: 1400px) {
//   .banner {
//     // width: auto;
//     width: auto;
//     height: 522px;
//     margin: 0 auto;
//     background: url(../../assets/banner2.png) no-repeat center center;
//     background-size: 100%;
//   }
// }
.characteristics {
  overflow: hidden;
  width: 1200px;
  margin: auto;
  text-align: center;

  p {
    font-size: 18px;
    font-weight: 400;
    color: #666666;
    letter-spacing: 1px;
    line-height: 1;
    margin-bottom: 38px;
    text-indent: 38px;
    text-align: left;
    line-height: 28px;
  }

  .dataInfo {
    display: flex;
    margin-bottom: 60px;
    // justify-content: space-between;
    align-items: center;

    .leftiamge {
      margin-left: 60px;
      margin-right: 156px;

      img {
        width: 374px;
        height: 438px;
      }
    }

    .rightInfo {
      width: 520px;

      .headerline {
        font-size: 24px;
        font-weight: 600;
        color: #333333;
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        img {
          width: 6px;
          height: 40px;
          margin-right: 20px;
        }
      }

      .header {
        font-size: 24px;
        font-weight: 600;
        color: #333333;
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        img {
          width: 30px;
          height: 30px;
          margin-right: 20px;
        }
      }

      .Info {
        width: 520px;

        text-align: justify;
        font-size: 18px;
        font-weight: 400;
        color: #666666;
        line-height: 27px;
      }
    }
  }
}

.zoomleft {
  width: 170px;
  height: 640px;
  background: linear-gradient(270deg, #ffffff 0%, #ffffff 100%);
  // filter: blur(10px);
  position: inherit;
  left: 35px;
  top: -12px;
  z-index: 999;
}

.zoomright {
  width: 140px;
  height: 640px;
  background: linear-gradient(270deg, #ffffff 0%, #ffffff 100%);
  // filter: blur(10px);
  position: inherit;
  left: -28px;
  top: 12px;
  z-index: 999;
}

.TabControl {
  width: 100%;
  display: flex;
  align-items: center;
  flex: 1;
  margin-top: 92px;

  img {
    width: 290px;
    height: 616px;
    margin-right: 76px;
  }

  .tabBtn {
    width: 240px;
    height: 240px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px 6px rgba(0, 0, 0, 0.04);
    border-radius: 30px;
    padding: 54px 30px 0;
    margin-right: 40px;

    /deep/.tabTitle {
      font-size: 24px;
      font-weight: 600;
      color: #333333;
      line-height: 1 !important;
      // margin-bottom: 0;
      margin: 0;
      text-indent: 0px !important;

    }

    .tabText {
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      line-height: 30px;
      text-indent: 0px !important;
    }
  }
}

.bgImage {
  width: 100%;
  height: 384px;
  background-image: url('../../assets/bgImage1.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow: hidden;
  text-indent: 0px;
  margin-top: 100px;

  .bgInfo {
    width: 440px;
    margin: 142px 0 0 692px;
    .bgImageTitle {
      font-size: 24px;
      font-weight: 600;
      color: #4F77E1;
      line-height: 33px;
      text-indent: 0;
    }

    .bgImageText {
      font-size: 18px;
      font-weight: 400;
      color: #4F77E1;
      line-height: 27px;
      text-indent: 0;
    }
  }
}
.bgImage2 {
  width: 100%;
  height: 384px;
  background-image: url('../../assets/bgImage2.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow: hidden;
  text-indent: 0px;
  margin: 126px 0 150px;
  .bgInfo {
    width: 440px;
    margin: 177px 0 0 80px;
    .bgImageTitle {
      font-size: 24px;
      font-weight: 600;
      color: #4F77E1;
      line-height: 33px;
      text-indent: 0;
    }

    .bgImageText {
      font-size: 18px;
      font-weight: 400;
      color: #4F77E1;
      line-height: 27px;
      text-indent: 0;
    }
  }
}</style>